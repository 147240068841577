/** @define gallery-hp;  weak */
.gallery-hp {
  justify-content: center;
}
.gallery-hp__first {
  justify-content: center;
  width: 100%;
}
@media only screen and (min-width: 1000px) {
  .gallery-hp__first {
    width: 50%;
  }
}
@media only screen and (max-width: 599px) {
  .gallery-hp__first .gallery-hp__link {
    max-width: 350px;
  }
}
.gallery-hp__secondary {
  justify-content: center;
  width: 100%;
}
@media only screen and (min-width: 1000px) {
  .gallery-hp__secondary {
    width: 50%;
  }
}
.gallery-hp__secondary .gallery-hp__link {
  max-width: 350px;
}
@media only screen and (min-width: 600px) {
  .gallery-hp__secondary .gallery-hp__link {
    width: 50%;
  }
}
.gallery-hp__link {
  overflow: hidden;
  /*@media only screen and (min-width: 700px) {
    &:first-child {
      width: 100%;
      max-width: none;
      border: 1px solid red;
    }
  }*/
}
.gallery-hp__link .img {
  transition: transform 0.4s ease-in-out;
}
.gallery-hp__link:hover .img {
  transform: scale(1.2);
}
.gallery-hp__link--enter {
  position: relative;
}
.gallery-hp__link--enter .entergallery__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  background-color: #e36c1b;
  transition: background-color 0.4s ease-in-out;
}
.gallery-hp__link--enter .entergallery__wrapper:hover {
  background-color: #229660;
}
.gallery-hp__link--enter .entergallery__text {
  width: 100%;
  height: 100%;
  font-size: 20px;
}
/*# sourceMappingURL=css/gallery-hp.css.map */